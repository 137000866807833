<template>
  <div>
    <v-navigation-drawer width="320" elevation="0" v-model="Drawer" :clipped="clipped" color="rgba(28, 59, 104, 1)"
      fixed app class="mr-9 ml-3 my-3 nav-height rounded-lg nav-left-custom">
      <div style="background-color:white;">
        <h5 class="text-center pt-5" style="color:#1C3B68;">{{ $t("leftSidebar.title") }}</h5>
        <h4 class="text-center pt-3" style="border-bottom:1px solid white"></h4>
      </div>

      <v-list two-line class="white--text left-sidebar p-0">
        <!-- <v-list-item v-for="(menu, index) in menus" :key="'menu-item-' + menu.id"
          :class="checkIsActive(menu?.page_link != null ? menu.page_link.page_url : menu.link) ? 'v-list-item--active' : ''"
          v-if="menu.children.length === 0" router link
          @click="extarnalUrl(menu?.page_link != null ? menu.page_link.page_url : menu.link, menu.link_type)"
          v-can="menu.link_type === 2 ? 'common' : menu?.page_link?.name">
          <v-list-item-title>{{ language === 'bn' ? menu.label_name_bn : menu.label_name_en }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-for="(menu, index) in menus" :key="'menu-group-' + menu.id" v-if="menu.children.length > 0"
          v-can="getPermissionName(menu)" :value="isSubMenuActive(menu)" class="sub_menus">
          <template v-slot:activator>
            <v-list-item-title class="white--text">{{ language === 'bn' ? menu.label_name_bn : menu.label_name_en
              }}</v-list-item-title>
          </template>

<v-list-group v-for="(subMenu, subIndex) in menu.children" :key="'sub-menu-group-' + subMenu.id"
  v-if="subMenu.children.length > 0" :value="isSubMenuActive(subMenu)" sub-group prepend-icon="mdi-chevron-down"
  v-can="getPermissionName(subMenu)">
  <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pl-1 white--text">{{ language === 'bn' ? subMenu.label_name_bn :
                  subMenu.label_name_en }}</v-list-item-title>
              </v-list-item-content>
            </template>

  <v-list-item v-for="(subSubMenu, subSubIndex) in subMenu.children" :key="'sub-sub-menu-item-' + subSubMenu.id"
    :class="checkIsActive(subSubMenu?.page_link != null ? subSubMenu.page_link.page_url : subSubMenu.link) ? 'v-list-item--active' : ''"
    router link
    @click="extarnalUrl(subSubMenu?.page_link != null ? subSubMenu.page_link.page_url : subSubMenu.link, subSubMenu.link_type)"
    v-can="subSubMenu?.page_link?.name">
    <v-list-item-icon>
      <v-icon>mdi-minus</v-icon>
    </v-list-item-icon>
    <v-list-item-title class="white--text">{{ language === 'bn' ? subSubMenu.label_name_bn :
      subSubMenu.label_name_en
      }}</v-list-item-title>
  </v-list-item>
</v-list-group>

<v-list-item v-else :key="'sub-menu-item-' + subMenu.id"
  :class="checkIsActive(subMenu?.page_link != null ? subMenu.page_link.page_url : subMenu.link) ? 'v-list-item--active' : ''"
  router link
  @click="extarnalUrl(subMenu?.page_link != null ? subMenu.page_link.page_url : subMenu.link, subMenu.link_type)"
  v-can="subMenu?.page_link?.name">
  <v-list-item-icon>
    <v-icon>mdi-minus</v-icon>
  </v-list-item-icon>
  <v-list-item-title class="white--text">{{ language === 'bn' ? subMenu.label_name_bn : subMenu.label_name_en
    }}</v-list-item-title>
</v-list-item>
</v-list-group> -->





        <v-list-item v-for="(menu, index) in menus" :key="'menu-item-' + menu.id"
          :class="menu.id === activeMenuId ? 'v-list-item--active' : ''" v-if="menu.children.length === 0" router link
          @click="setActiveMenu(menu.id); extarnalUrl(menu?.page_link != null ? menu.page_link.page_url : menu.link, menu.link_type)"
          v-can="menu.link_type === 2 ? 'common' : menu?.page_link?.name">
          <v-list-item-icon>
            <i  :class="menu.icon??'fas fa-circle-dot'"></i>
          </v-list-item-icon>
          <v-list-item-title>{{ language === 'bn' ? menu.label_name_bn : menu.label_name_en }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-for="(menu, index) in menus" :key="'menu-group-' + menu.id" v-if="menu.children.length > 0"
          v-can="getPermissionName(menu)" :value="menu.id === activeMenuId" class="sub_menus">
          <template v-slot:activator>
            <v-list-item-icon>
              <i  :class="menu.icon??'fas fa-circle-dot'"></i>
            </v-list-item-icon>
            <v-list-item-title class="white--text">{{ language === 'bn' ? menu.label_name_bn : menu.label_name_en
              }}</v-list-item-title>
          </template>

          <v-list-group v-for="(subMenu, subIndex) in menu.children" :key="'sub-menu-group-' + subMenu.id"
            v-if="subMenu.children.length > 0" :value="subMenu.id === activeMenuId" sub-group append-icon="fas fa-chevron-right"
             v-can="getPermissionName(subMenu)">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pl-1 white--text">{{ language === 'bn' ? subMenu.label_name_bn :
                  subMenu.label_name_en }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:append-icon>
              <v-icon>{{ $attrs.value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </template>
            <template v-slot:prepend-icon>
              <i :class="subMenu.icon??'fas fa-minus'"></i>
            </template>

            <v-list-item v-for="(subSubMenu, subSubIndex) in subMenu.children"
              :key="'sub-sub-menu-item-' + subSubMenu.id"
              :class="subSubMenu.id === activeMenuId ? 'v-list-item--active' : ''" router link
              @click="setActiveMenu(subSubMenu.id); extarnalUrl(subSubMenu?.page_link != null ? subSubMenu.page_link.page_url : subSubMenu.link, subSubMenu.link_type)"
              v-can="subSubMenu?.page_link?.name">
              <v-list-item-icon>
                  <i  :class="subMenu.icon??'fas fa-minus'"></i>
                </v-list-item-icon>
              <v-list-item-title class="white--text">{{ language === 'bn' ? subSubMenu.label_name_bn :
                subSubMenu.label_name_en
                }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="'sub-menu-item-' + subMenu.id"
            :class="subMenu.id === activeMenuId ? 'v-list-item--active' : ''" router link
            @click="setActiveMenu(subMenu.id); extarnalUrl(subMenu?.page_link != null ? subMenu.page_link.page_url : subMenu.link, subMenu.link_type)"
            v-can="subMenu?.page_link?.name">
            <v-list-item-icon>
                <i  :class="subMenu.icon??'fas fa-minus'"></i>
              </v-list-item-icon>
            <v-list-item-title class="white--text">{{ language === 'bn' ? subMenu.label_name_bn : subMenu.label_name_en
              }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
//shanto
export default {
  data() {
    return {
      activeMenuId: null,
      menu: false,
      clipped: false,
      fixed: false,
    };
  },
  computed: {
    menus() {
      return this.$store.state.menus;
    },
    language() {
      return this.$store.getters.getAppLanguage;
    },
    Drawer: {
      get() {
        return this.$store.state.Drawer;
      },
      set(v) {
        return this.$store.commit("setDrawer", v);
      },
    },
  },

  methods: {
    setActiveMenu(id) {
      this.activeMenuId = id;
    },


    // checkIsActive(url) {
    //   return this.$route.path === url;
    // },
    // isSubMenuActive(menu) {
    //   if (menu.children && menu.children.length > 0) {
    //     return menu.children.some(subMenu =>
    //       this.isSubMenuActive(subMenu) ||
    //       this.checkIsActive(subMenu?.page_link != null ? subMenu.page_link.page_url : subMenu.link)
    //     );
    //   }
    //   return this.checkIsActive(menu?.page_link != null ? menu.page_link.page_url : menu.link);
    // },


    extarnalUrl(url, type) {
      if (type === 1) {
        if (this.$route.path !== url && url !== null) {
          this.$router.push(url);
        }
      } else if (type === 2) {
        window.open(url, '_blank');
      }
    },
    getPermissionName(menu) {
      if (menu.permission != null) {
        return menu.permission;
      }
      if (menu.link_type === 2) {
        return 'common';
      }
      if (menu?.page_link?.name) {
        return menu?.page_link?.name;
      }
    },
  },
  mounted() {
    this.$store.dispatch('getAllMenus');
  },
};
</script>

<style>
.list_size {
  color: #000000;
}

.v-list.left-sidebar.v-sheet.theme--light .v-list-item__title {
  font-size: 16px;
}

.left-sidebar .theme--light.v-icon {
  color: #f6f5f5dd;
}

.left-sidebar .v-list-item {
  min-height: 40px !important;
  border-bottom: 1px solid powderblue !important;
}

.left-sidebar .v-list-group__items {
  padding-left: 15px !important;
}

.left-sidebar .v-list-group__items .v-list-item {
  padding-left: 0px !important;
}

.left-sidebar .v-list-item .v-list-item__icon {
  margin-bottom: 5px !important;
}

.theme--light.v-navigation-drawer .v-divider {
  margin-top: 68px;
}

.theme--light.v-divider {
  border-color: whitesmoke !important;
}

.v-navigation-drawer__content {
  height: 100%;
}

.v-navigation-drawer .v-list-item .v-list-item__title,
.v-navigation-drawer .v-list-group__header .v-list-item__title {
  color: white !important;
}

.v-navigation-drawer .v-list-item-icon .v-icon,
.v-navigation-drawer .v-list-group__header .v-icon {
  color: white !important;
}

.v-navigation-drawer .v-list-item.v-list-item--active {
  background-color: white !important;
  color: #1C3B68 !important;
}

.v-navigation-drawer .v-list-item.v-list-item--active .v-list-item__title {
  color: #1C3B68 !important;
}

.v-navigation-drawer .v-list-item.v-list-item .v-list-item__icon{
  color: white !important;
}
.v-navigation-drawer .v-list-item.v-list-item--active .v-list-item__icon{
  color: #1C3B68 !important;
}

</style>
